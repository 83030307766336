import { styled } from "@mui/material/styles"
import { Button } from "@/components/ui/inputs/Button"
import type { FC } from "react"
import Icon from "@mdi/react"
import { mdiCheckCircleOutline, mdiCloseCircleOutline } from "@mdi/js"

export const btnWidth = "320px"
export const maxWidth = "416px"

export const AuthButton = styled(Button)(() => ({
  width: btnWidth,
}))

// FIXME: https://stackoverflow.com/questions/49784294/warning-received-false-for-a-non-boolean-attribute-how-do-i-pass-a-boolean-f
// see: https://styled-components.com/docs/api#transient-props
// warning still persists...
// export type StatusStack = StackProps & { $valid?: boolean }
// export const StyledAuthStatusStack = styled(Stack)<StatusStack>(
//   ({ theme, $valid }) => ({
//     width: btnWidth,
//     color: $valid ? theme.palette.success.light : theme.palette.text.secondary,
//   })
// )

export type StatusIcon = FC<{ valid?: boolean }>
export const AuthStatusIcon: StatusIcon = ({ valid }) => (
  <Icon
    path={valid ? mdiCheckCircleOutline : mdiCloseCircleOutline}
    size={"18px"}
  />
)
// export const AuthStatusIcon = styled(StatusIcon)(({}) => ({}))
