"use client"

import { useTheme } from "@mui/material/styles"

import { useParams, useRouter, useSearchParams } from "next/navigation"
import {
  type ReactNode,
  type SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { type ZodFormattedError, z } from "zod"

import useAside from "@/app/_components/Aside/hooks/useAside"
import deApp from "@/app/_messages/de.json"
import { Typography } from "@/components/ui/data-display/Typography"
import { Alert } from "@/components/ui/feedback/Alert"
import { AlertTitle } from "@/components/ui/feedback/AlertTitle"
import { Button } from "@/components/ui/inputs/Button"
import PasswordTextField from "@/components/ui/inputs/PasswordField"
import { TextField } from "@/components/ui/inputs/TextField"
import { Box } from "@/components/ui/layout/Box"
import { Stack } from "@/components/ui/layout/Stack"
import { fallbackLocale, useTranslation } from "@/i18n"
import { FEATURE_FLAG_ENABLE_REGISTRATION } from "@/utils/env"
import { ACTIVATE_SESSION_COOKIE } from "@/utils/middleware"
import { deleteCookie } from "cookies-next"
import type { Route } from "next"
import Link from "next/link"
import { btnWidth, maxWidth } from "../../../_components/Auth.styled"
import AuthPaperHeader from "../../../_components/AuthPaperHeader"
import { ZodEmailValidator } from "../../../_utils/validators"
import de from "../../_messages/de.json"
import type { AuthError } from "@/utils/lib"
import { signIn } from "next-auth/react"

export const testPrefix = "AuthSigninForm"

export interface SigninFormProps {
  children?: ReactNode
}
export interface SigninSchema {
  email: string
  password: string
}

export default function SigninForm() {
  const { locale = fallbackLocale } = useParams()
  const router = useRouter()
  const searchParams = useSearchParams()
  const { asideSearch } = useAside()
  const theme = useTheme()
  const { t, tHtml } = useTranslation(de)
  const { t: tApp } = useTranslation(deApp)

  const redirect = decodeURIComponent(
    searchParams?.get("redirect") ?? `/${locale}/in`,
  )

  const [error, setError] = useState<Partial<AuthError> | null>(null)
  const [busy, setBusy] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] =
    useState<ZodFormattedError<SigninSchema>>()
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const signinClicked = useRef<boolean>(false)
  const SigninSchema = z.object({
    email: ZodEmailValidator,
    password: z.string().min(8),
  })

  function getErrorTitle(error: Partial<AuthError>) {
    if (error.status === 401) {
      return t("error.invalidToken.title")
    }
    return t("error.signin.title")
  }

  function getErrorMessage(error: Partial<AuthError>) {
    if (error.status === 401) {
      return t("error.invalidToken.message")
    }
    return t("error.signin.message")
  }

  const handleSigninClick = useCallback(
    async (event: SyntheticEvent) => {
      signinClicked.current = true
      event.preventDefault()
      setBusy(true)
      const form = { email, password }
      const res = SigninSchema.safeParse(form)

      // delete all activation cookies to prevent issues at signin
      for (const key of Object.values(ACTIVATE_SESSION_COOKIE)) {
        deleteCookie(key)
      }

      if (!res.success) {
        setValidationErrors(res?.error?.format())
        setBusy(false)
      } else {
        const response = await signIn("credentials", {
          redirect: false,
          email,
          password,
        })
        setValidationErrors(undefined)
        if (response?.error) {
          setError({
            name: response.error,
            status: response.status,
          })
          setBusy(false)
        } else {
          router.push(redirect as Route)
        }
      }
      setPassword("")
    },
    [SigninSchema, email, password, redirect, router],
  )
  const validate = useCallback(
    (form: SigninSchema) => {
      const validation = SigninSchema.safeParse(form)
      setValidationErrors(
        validation?.success ? undefined : validation?.error?.format(),
      )
    },
    [SigninSchema],
  )
  useEffect(() => {
    const searchParams = new URLSearchParams(
      window.location?.hash?.split("#")?.at(1) ?? "",
    )
    const error = searchParams.get("error") ?? ""
    const error_code = searchParams.get("error_code") ?? ""
    const error_description = searchParams.get("error_description") ?? ""

    if (error || error_code || error_description) {
      setError({
        name: error,
        status: Number.isNaN(Number.parseInt(error_code, 10))
          ? 500
          : Number.parseInt(error_code, 10),
        message: error_description,
      })
    }
  }, [])

  return (
    <form onSubmit={handleSigninClick}>
      <fieldset>
        <Stack
          spacing={theme.spacing(2.5)}
          className="h-full w-full"
          alignItems={"center"}
          data-testid={"AuthSigninForm"}
        >
          <AuthPaperHeader title={t("title")} subTitle={tHtml("subTitle")} />

          <Stack
            sx={{
              maxWidth,
              width: btnWidth,
            }}
            spacing={theme.spacing(2.5)}
          >
            {error && (
              <Alert severity="error" data-testid={"AuthSigninFormAlertError"}>
                <AlertTitle>{getErrorTitle(error)}</AlertTitle>
                {getErrorMessage(error)}
              </Alert>
            )}

            <TextField
              data-testid={"AuthSigninFormInputEmail"}
              type="email"
              name="email"
              label={t("textField.inputEmail.label")}
              value={email}
              disabled={busy}
              onChange={(e) => {
                if (signinClicked.current) {
                  const form = { email, password: e?.target?.value }
                  validate(form)
                }
                setEmail(e?.target?.value?.trim())
              }}
              error={Boolean(validationErrors?.email?._errors?.length)}
              helperText={
                validationErrors?.email?._errors?.length
                  ? validationErrors?.email?._errors?.at(0)
                  : ""
              }
              // prevent blur on enter/tab, otherwise the form will not be submitted
              onKeyDown={() => {}}
            />
            <PasswordTextField
              testId={"AuthSigninFormInputPassword"}
              type="password"
              name="password"
              autoComplete="current-password"
              label={t("textField.inputPassword.label")}
              value={password}
              disabled={busy}
              error={Boolean(validationErrors?.password?._errors?.length)}
              helperText={
                validationErrors?.password?._errors?.length
                  ? tApp("validation.password.message")
                  : ""
              }
              onChange={(e) => {
                if (signinClicked.current) {
                  const form = { email, password: e?.target?.value }
                  validate(form)
                }
                setPassword(e?.target?.value)
              }}
              // prevent blur on enter/tab, otherwise the form will not be submitted
              onKeyDown={() => {}}
            />

            <Box width={btnWidth} className="flex justify-end">
              <Button
                data-testid={"AuthSigninFormButtonForgotPassword"}
                sx={{ color: theme.palette.text.primary }}
              >
                <Link href={`/${locale}/auth/forgot-password?${asideSearch}`}>
                  {t("button.forgotPassword.label")}
                </Link>
              </Button>
            </Box>

            <Button
              fullWidth
              type="submit"
              data-testid={"AuthSigninFormButtonSignin"}
              variant="contained"
              color="primary"
              disabled={!!validationErrors}
              onClick={handleSigninClick}
              loading={busy}
            >
              {t("button.signin.label")}
            </Button>
            {FEATURE_FLAG_ENABLE_REGISTRATION && (
              <Stack direction="row" spacing={1}>
                <Typography
                  color={theme.palette.text.disabled}
                  className="self-center"
                >
                  {/* TODO: this wasn't resolved correctly, we should find an easy way to loop over all translations and check if they are displayed, also ensuring we do not keep unused translations */}
                  {t("button.register.description")}
                </Typography>
                <Button
                  className="self-end"
                  data-testid={"AuthSigninFormButtonRegister"}
                  sx={{ color: theme.palette.text.primary }}
                >
                  <Link href={`/${locale}/auth/registration?${asideSearch}`}>
                    {t("button.register.label")}
                  </Link>
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </fieldset>
    </form>
  )
}
