import { z } from "zod"
import isEmail from "validator/lib/isEmail"
import isURL, { type IsURLOptions } from "validator/lib/isURL"
import { getTranslation } from "@/i18n"
import de from "@/app/_messages/de.json"

export const checkNumbers = () => z.string().regex(/[0-9]/)
export const checkLetters = () => z.string().regex(/[A-Za-z]/)
export const checkLength = () => z.string().min(8)
export const checkComplexity = () =>
  z.intersection(checkNumbers(), checkLetters())
export const ZodEmailValidator = z
  .string()
  .min(5)
  .refine(isEmail, {
    message: getTranslation(de).t("validation.email.message") ?? "",
  })

export const checkEmail = (value: string) =>
  ZodEmailValidator.safeParse(value).success
export const checkMatch = (str: string) =>
  z.string().includes(str).length(str.length)

export const checkURL = (value: string, options?: IsURLOptions | undefined) => {
  return isURL(value, options)
}
